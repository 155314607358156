<template>
  <v-app>
    <div class="invoice--container">
      <div class="header--action" v-if="$route.query.mobile !== 'true'">
        <div class="row align-center">
          <div class="col">{{ this.document ? this.document.invoice_number : 'Invoice' }}</div>
          <div class="col-auto">
            <button class="btn btn-primary btn-block" @click="print">
              {{ $t('invoice.print') }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="container pa-4 my-4"
        :style="{
          'margin-top': headerHeight + 'px',
        }"
      >
        <invoice v-if="$route.name === 'invoice'"></invoice>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
const Invoice = () => import('@/components/order/invoice');
import { decryptToken } from '@/utils/helpers';

export default {
  head() {
    return {
      title: this.document ? this.document.invoice_number : 'Invoice',
    };
  },
  async fetch({ store, params, route }) {
    // !! Get data invoice harus di fetch agar tetap muncul saat dibuka dari aplikasi !!
    let token = store.state.global.token;
    if (!store.state.global.loggedIn) {
      if (route.query.token) {
        token = decryptToken(route.query.token);
      }
    }
    await store.dispatch('order/getInvoice', {
      uuid: params.uuid,
      token: token,
    });
  },

  components: {
    Invoice,
  },
  computed: {
    ...mapState({
      headerHeight: (state) => state.size.headerHeight,
      document: (state) => state.order.invoice,
      isLoggedIn: (state) => state.global.loggedIn,
    }),
  },
  methods: {
    print() {
      window.print();
    },
  },
};
</script>
